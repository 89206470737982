import { render, staticRenderFns } from "./userSpace.vue?vue&type=template&id=64c6e176&"
import script from "./userSpace.vue?vue&type=script&lang=js&"
export * from "./userSpace.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutSkipLinks: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/SkipLinks.vue').default,LayoutHeader: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/Header.vue').default,LayoutDrawer: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/Drawer/index.vue').default,LayoutHeaderBack: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/HeaderBack.vue').default,LayoutTabMenu: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/TabMenu.vue').default,Menu: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Menu/index.vue').default,UiAside: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Ui/Aside.vue').default,LayoutFooter: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/Footer.vue').default,LayoutDialogs: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/Dialogs.vue').default,LayoutLayer: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Layout/Layer.vue').default,FormMicrosoftDynamics: require('/codebuild/output/src1030108091/src/med-el-hearpeers/components/Form/MicrosoftDynamics.vue').default})
